<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-dialog v-model="dateRangeDialog" width="700" transition="dialog-bottom-transition">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <h3>دیاریکردی ماوە</h3>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn text @click="search">
                                    <v-icon>mdi-magnify</v-icon>
                                    گەڕان
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-container fluid>
                            <v-form ref="searchForm">
                                <v-row>
                                    <v-col>
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="from"
                                                    label="لە بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" outlined dense
                                                    :rules="rules.date"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="from" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="to"
                                                    label="بۆ بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" outlined dense
                                                    :rules="rules.date"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="to" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" width="700" transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs"
                            v-on="on"><v-icon>mdi-plus</v-icon><v-icon>mdi-pus</v-icon> زیادکردنی کۆمپانیا </v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark outlined :class="isUpdate ? 'orange accent-2' : 'blue accent-2'" elevation="1">
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn text @click="isUpdate ? update() : create()">
                                    <v-icon>{{ isUpdate ? 'mdi-check' : 'mdi-plus' }}</v-icon>
                                    {{ isUpdate ? 'نوێکردنەوەی کۆمپانیا' : 'زیادکردنی کۆمپانیا' }} </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-form ref="supplier">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense v-model="supplier.supplier_name"
                                            label="ناوی کۆمپانیا" :rules="rules.supplier_name" outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense v-model="supplier.mobile"
                                            label="مۆبایل" :rules="rules.mobile" outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense v-model="supplier.address"
                                            label="ناونیشان" outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense v-model="supplier.debt"
                                            label="قەرزی کۆن" outlined></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-card class="rounded-lg px-1 py-1">
                    <v-data-table :headers="headers" :items="suppliers" hide-default-footer :items-per-page="20"
                        :loading="loading">
                        <template v-slot:[`item.nowDebt`]="{ item }">
                            {{ '$' + item.nowDebt.toLocaleString() }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon"
                                    @click="supplier = { ...item }; dialog = true">mdi-pencil</v-icon>
                                <v-icon large class="ml-2" v-else v-bind="item">mdi-pencil</v-icon>
                            </v-hover>

                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon"
                                    @click="remove(item.supplier_id)">mdi-delete</v-icon>
                                <v-icon large class="ml-2" v-else v-bind="item">mdi-delete</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon"
                                    @click="view(item.supplier_id)">mdi-eye</v-icon>
                                <v-icon large class="ml-2" v-else v-bind="item">mdi-eye</v-icon>
                            </v-hover>
                        </template>
                    </v-data-table>
                    <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfSupplier / 20)"
                        v-model="table.page"></v-pagination>
                </v-card>
            </v-col>
        </v-row>
        <notification />
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfSupplier: 0
            },
            dateRangeDialog: false,
            menu1: false,
            menu2: false,
            supplier_id: 0,
            dialog: false,
            from: '',
            to: '',
            loading: false,
            suppliers: [],
            headers: [
                { text: 'ناوی کۆمپانیا', value: 'supplier_name', align: 'center' },
                { text: 'مۆبایل', value: 'mobile', align: 'center' },
                { text: 'ناونیشان', value: 'address', align: 'center' },
                { text: 'قەرز', value: 'nowDebt', align: 'center' },
                { text: 'کردارەکان', value: 'actions', align: 'center' },
            ],
            supplier: {
                supplier_id: 0,
                supplier_name: '',
                mobile: '',
                address: '',
                debt: 0
            },
            rules: {
                supplier_name: [
                    (v) => {
                        return (v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                mobile: [
                    (v) => {
                        return (v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
            }
        }
    },
    created() {
        this.getSuppliers()
    },
    methods: {
        async getSuppliers() {
            this.loading = true
            const { data } = await this.axios.get('/supplier', { params: { page: this.table.page } })
            this.suppliers = data.suppliers
            this.table.numberOfSupplier = data.count
            this.loading = false
        },
        async create() {
            if (this.$refs.supplier.validate()) {
                try {
                    await this.axios.post('/supplier', this.supplier)
                    this.reset()
                    this.getSuppliers()
                    this.emitter.$emit('notify', { type: 'success', text: 'کۆمپانیاکە زیادکرا' })
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', { type: 'error', text: `${error.response.data.field} is duplicate` })
                    }
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        async update() {
            if (this.$refs.supplier.validate()) {
                try {
                    const supplier = this.supplier
                    delete supplier.nowDebt
                    delete supplier.debt
                    await this.axios.patch('/supplier/' + this.supplier.supplier_id, this.supplier)
                    this.reset()
                    this.getSuppliers()
                    this.emitter.$emit('notify', { type: 'success', text: 'کۆمپانیاکە نوێکرایەوە' })
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        async remove(supplier_id) {
            this.$confirm('ئایا دڵنیایت لە سڕینەوەی ئەم کۆمپانیایە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/supplier/' + supplier_id)
                    this.getSuppliers()
                    this.emitter.$emit('notify', { type: 'success', text: 'کۆمپانیاکە سڕدرایەوە' })
                } catch (error) {
                    console.log(error);
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            })
        },
        reset() {
            this.supplier = {
                supplier_id: 0,
                supplier_name: '',
                mobile: '',
                address: '',
                debt: 0
            }
            this.$refs.supplier.resetValidation();
        },
        async view(supplier_id) {
            this.supplier_id = supplier_id
            this.dateRangeDialog = true
        },
        async search() {
            if (this.$refs.searchForm.validate()) {
                window.open('/report/supplier/' + this.supplier_id + '/' + this.from + '/' + this.to, '_blank')
            }

        },
        show(supplier_id) {
            window.open('/report/supplier/' + supplier_id, '_blank', 'width=595', 'height=842')
        }
    },
    computed: {
        isUpdate() {
            return this.supplier.supplier_id
        }
    },
    watch: {
        'table.page': function () {
            this.getSuppliers()
        }
    }


}
</script>

<style >
body {
    background-color: grey !important
}

th {
    font-weight: bold;
    font-size: 14px !important;
}

tbody tr:nth-of-type(odd) {
    background-color: #e2e9f0;
}

#icon {
    cursor: pointer;
}
</style>